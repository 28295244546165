
const drawerWidth = 360;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2)
    },
    appFrame: {
        height: '100vh',
        zIndex: 1,
        overflow: 'auto',
        position: 'relative',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.background.default,
      },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'appBarShift-left': {
        marginLeft: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'auto'
      },
      'content-left': {
        marginLeft: -drawerWidth,
      },
      'content-right': {
        marginRight: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      'contentShift-left': {
        marginLeft: 0,
      },
      'contentShift-right': {
        marginRight: 0,
      },
      menuButton: {
        marginLeft: 12,
        marginRight: 20,
      },
      hide: {
        display: 'none',
      },
      avatar: {
        backgroundColor: theme.palette.secondary.main,
      },
      buttonPadding : {
        marginRight: theme.spacing(3)
      }
      
    
  });

  export default styles;
