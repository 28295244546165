import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './SignInStyles';


class EmailVerifiedSuccess extends Component {

  login = () => {
    this.props.auth.login();
  }

  

  render() {

    const { classes } = this.props;

    return (
        <div className={classes.root}>
          <div className={classes.layout}>
            <Paper className={classes.paper} elevation={10}>

              <CheckCircle className={classes.verifiedIcon}/>

              <Typography component="h1" variant="h5" className={classes.title} color="secondary">
                Email Verification
              </Typography>


              <Typography component="p" variant="body1" align="left" className={classes.intro}>
                Your email was verified.  You can continue using the application.
              </Typography>

              <br></br>
              
              <Button color="primary" onClick={this.login} className={classes.button}>
                  Home
              </Button>
    
              
            </Paper>
          </div>
        </div>  
      );
  }

  
}

export default withStyles(styles)(EmailVerifiedSuccess);
