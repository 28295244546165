import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

const validator = {
    validateCommonFields: ({effectiveDate, name, registeredAddress}) => {
        let required = [];
    
        if (!effectiveDate ) {
            console.log('date',effectiveDate);
            required.push('effectiveDate');
        }
        
        if (isNil(name) || isEmpty(name)) {
            required.push('name');
        }

        if (isNil(registeredAddress) || isEmpty(registeredAddress)) {
            required.push('registeredAddress');
        }

        return required;
    },

    validateMsa: ({companyNumber}) => {
        let required = [];
        if (isNil(companyNumber) || isEmpty(companyNumber)) {
            required.push('companyNumber');
        }

        return required;

    },
    validateMsaAU: ({companyNumber, state}) => {
        let required = validator.validateMsa({companyNumber});
        if (isNil(companyNumber) || isEmpty(companyNumber)) {
            required.push('companyNumber');
        }

        if (isNil(state) || isEmpty(state)) {
            required.push('state');
        }

        return required;

    },
    validateConf: ({purpose, otherPurpose, terms, other}) => {

        let required = [];

        if (isNil(purpose) || isEmpty(purpose)) {
            required.push('purpose');
        }

        if (purpose === 'Other' && (isNull(otherPurpose) || isEmpty(otherPurpose))) {
            required.push('otherPurpose');
        }

        if (isNil(terms) || isEmpty(terms)) {
            required.push('terms');
        }

        if (terms === 'Other' && (isNull(other) || isEmpty(other))) {
            required.push('other');
        }

        return required;

    },

    validateConfAU: ({purpose, otherPurpose, terms, other, state}) => {

        let required = validator.validateConf({purpose, otherPurpose, terms, other});
        if (isNil(state) || isEmpty(state)) {
            required.push('state');
        }

        return required;

    }
}

export default validator;
