import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import history from './history';

import styles from './SignInStyles';


class EmailVerification extends Component {

  login = () => {
    //this.props.auth.login();
    history.replace("/");
  }

  render() {

    const { classes } = this.props;

    return (
        <div className={classes.root}>
          <div className={classes.layout}>
            <Paper className={classes.paper} elevation={10}>

              <EmailIcon className={classes.email}/>

              <Typography component="h1" variant="h5" className={classes.title} color="secondary">
                Email Verification
              </Typography>


              <Typography component="p" variant="body1" align="left" className={classes.intro}>
              Please check your inbox for a verification email sent by Gaido.  You need to confirm your account to successfully log in.
              </Typography>

              <br></br>
              
              <Button color="primary" onClick={this.login} className={classes.button}>
                  Back to Login Page
              </Button>
    
              
            </Paper>
          </div>
        </div>  
      );
  }

  
}

export default withStyles(styles)(EmailVerification);
