import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import NZBNSearchTablePagination from './NZBNSearchTablePagination';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
  });

class SearchTable extends Component {

    state = {
        selected: [],
        rowsPerPage: 5,
        page: 0,
        data: []
    }

    handleClick = (event, id) => {
        let newSelected = [];

        newSelected.push(id);
        
        this.setState({ selected: newSelected });
        this.props.handleAddressSelect(id);

    }

    handleRowDblClick(event, id) {
        this.handleClick(event, id);
        this.props.handleRowDblClick(id);
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {

        const { items, classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            {this.props.showCompanyNumber && <TableCell align="right">Company Number</TableCell>}
                            
                            <TableCell align="right">Business Number</TableCell>
                            <TableCell >Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {

                        let key = row.sourceRegisterUniqueId + row.nzbn + row.entityName;

                        const isSelected = this.isSelected(key);
                        return (
                            <TableRow 
                                key={key}
                                hover
                                role="checkbox"
                                selected={isSelected}
                                onClick={event => this.handleClick(event, key)}
                                onDoubleClick={event => {
                                    //console.log('double-click event');
                                    this.handleRowDblClick(event, key)
                                }

                                }
                            >
                                <TableCell>
                                    <Checkbox  checked={isSelected}/>
                                </TableCell>
                                <TableCell>
                                    {row.entityName}
                                </TableCell>
                                {this.props.showCompanyNumber && <TableCell align="right">{row.sourceRegisterUniqueId}</TableCell>}
                                
                                <TableCell align="right">{row.nzbn}</TableCell>
                                <TableCell >{row.address}</TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>

                    <NZBNSearchTablePagination 
                        rowsPerPage={rowsPerPage}
                        page={page}
                        changePageHandler={(event, page) => {
                            this.setState({ page });
                        }}
                        data={items}
                    />

                </Table>
            </Paper>
        );
    }
}

export default withStyles(styles)(SearchTable);
