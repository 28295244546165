const styles = theme => ({
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    company: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
});

export default styles;
