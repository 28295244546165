export const COUNTRIES = [
    {
        "country": "NZ",
        "description": "New Zealand",
        "states": []
    },
    {
        "country": "AU",
        "description": "Australia",
        "states": [
            { "name": "Australian Capital Territory", "code": "ACT" },
            { "name": "New South Wales", "code": "NSW" },
            { "name": "Northern Territory", "code": "NT" },
            { "name": "Queensland", "code": "QLD" },
            { "name": "South Australia", "code": "SA" },
            { "name": "Tasmania", "code": "TAS" },
            { "name": "Victoria", "code": "VIC" },
            { "name": "Western Australia", "code": "WA" },
        ]
    }
]