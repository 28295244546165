import config from './Config';
import superagent from 'superagent';


class AUBusinessNumberSearch {

  constructor() {
    this.searchType = "";
  }

  fetchEntities(searchString) {

    const { auBusinessSearchAPI, abnApiToken } = config;
    this.searchString = searchString;
    
    let params = {
      "guid": abnApiToken,
      "searchString": searchString
    };

    // return a Promise
    return superagent
      .get(auBusinessSearchAPI)
        .query(params); 
      

  }
  
   prepareEntitySearchResult(res, searchString) {
    
    return res.body.result.map((item) => {

      return {
        entityName: item.name,
        sourceRegisterUniqueId: item.acn,
        nzbn: item.abn,
        address: `${item.stateCode} ${item.postCode}`
      }

    });
    

  }

  async searchCompanyDetails (searchString) {

    const res = await this.fetchEntities(searchString);
    
    if (res && res.body) {
  
        return this.prepareEntitySearchResult(res, searchString);
  
    } else {
        return [];
    }
  
  }


}



export default AUBusinessNumberSearch;




