import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DatePicker } from '@material-ui/pickers';

const AgreementDate = (props) => {

    const [disableEffectiveDate, setDisableEffectiveDate] = useState(false);

    const { changeHandler, state, dateHandler } = props;

    const onExecutionToggleHandler = (e) => {
        setDisableEffectiveDate(e.target.checked);
        changeHandler(e);
    }

    return (

        <React.Fragment>
            <Grid item xs={12} sm={6}>
            
                <DatePicker
                    required
                    id="effectiveDate"
                    name="effectiveDate"
                    label="Effective Date"
                    format="dd/MM/yyyy"
                    placeholder="dd/MM/yyyy"
                    value={state.effectiveDate}
                    onChange={dateHandler}
                    animateYearScrolling={false}
                    showTodayButton
                    error={state.formErrors.includes("effectiveDate")}
                    disablePast
                    helperText="Date agreement will start"
                    disabled={disableEffectiveDate}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    control={
                    <Switch
                        name="onExecution"
                        color="primary"
                        onChange={onExecutionToggleHandler}
                    />
                    }
                    label="On Execution"
                />
            </Grid>
        </React.Fragment>    

    );
}
 
export default AgreementDate;