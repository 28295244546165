import React, { Component, Fragment } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import SignIn from './SignIn';
import Auth from './Auth/Auth';
import Callback from './Callback/Callback';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import history from './history';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ErrorPage from './ErrorPage';
import Main from './Main';

import EmailVerification from './EmailVerification';
import EmailVerifiedSuccess from './EmailVerifiedSuccess';

//import SecuredRoute from './SecuredRoute';

const auth = new Auth();

const uiTheme = createMuiTheme({
  palette: {
    
    primary: {
      main: '#ac2330'
    },
    background: {
      default: '#f3f3f3' //'#dad9d6'
    },
  },
  typography: {
    //useNextVariants: true,
    fontFamily: 'FujitsuSans'
    

  },
  overrides: {
    
    MuiListItem: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'rgba(245, 222, 179, 0.6)',
          borderRight: 'solid 2px #ac2330'
        }
      }
    },
    /*
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['FujitsuSans'],
      },
    },
    */
  }
});

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden'
  }
  
});

class App extends Component {

  handleAuthentication = async ({location}) => {

    console.log('location', location);
    
    if (/access_token|id_token|error/.test(location.hash)) {
      console.log("handle authentication");

      try {

        await auth.handleAuthentication();

        if (auth.getReferrer()) {
          history.replace(auth.getReferrer());
        } else {
          history.replace('/home/nz/msa');
        }

        

      } catch(err) {

        console.log(err);

        const {error, errorDescription} = err;

        if (error === "unauthorized" && errorDescription === "Please verify your email before logging in.") {
          history.replace('/verify');
        } else {
          history.replace({
            pathname: '/error',
            state: {
              error: err
            }
          });
        }
      }

    }
  }

  render() {

    const { classes} = this.props;
    
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>

          <Fragment>
            <CssBaseline />

            <MuiThemeProvider theme={uiTheme}>

              <div className={classes.root}>
                <Switch>

                <Route exact path="/callback" render={(props) => {
                    console.log("in callback...", auth.useAzure);
                    if (!auth.useAzure) {
                      this.handleAuthentication(props);
                    }
                    return <Callback {...props} />
                  }}/>  

                  <Route exact path="/error" render={(props) => <ErrorPage auth={auth} {...props}/> } />
                  <Route exact path="/verify" render={(props) => <EmailVerification auth={auth} {...props}/> } />
                  <Route exact path="/verified" render={(props) => <EmailVerifiedSuccess auth={auth} {...props}/> } />

                  <Route path="/" render={(props) => {
                    if (auth.isAuthenticated() !== true) {
                      auth.setReferrer(props.location.pathname);
                      return <SignIn auth={auth} {...props} />
                    } else {
                      return <Main auth={auth} {...props} />
                    }
                  }}/>

                  

                  

                </Switch>
                
              </div>

              
            </MuiThemeProvider>

          </Fragment>

        </Router>
      </MuiPickersUtilsProvider>
      
      

      
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
