import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import superagent from 'superagent';
import httpStatus from 'http-status';
import debounce from 'lodash/debounce';
import Divider from '@material-ui/core/Divider';

import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import styles from './ConfidentialityAgreementFormStyles';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import config from './Config';
import AgreementDate from './AgreementDate';

import { COUNTRIES } from "./config/countries";

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {

  return (
    <React.Fragment key={suggestion.id}>
      <MenuItem
        {...itemProps}
        component="div"
      >
        {suggestion.full_address}
      </MenuItem>
      <Divider />
    </React.Fragment> 
  );
}



class CAFormAU extends Component {

  constructor(props) {
    super(props);

    // delays the call to getSuggestions by 300ms
    this.getSuggestions = debounce(this.getSuggestions, 300);

  }

  getSuggestions(event) {

    if (!event.target.value) {
      this.setState({
        addressSuggestions: []
      })
      return;
    }
  
    const inputValue = event.target.value.trim().toLowerCase();
  
    let {auAddressFinderAPI, auAddressFinderAPIKey} = config;

    superagent
        .get(auAddressFinderAPI)
        .query({
          key: auAddressFinderAPIKey,
          q: inputValue,
          format: 'json',
          gnaf: 1
        })
        
        .accept('application/json')
        .then((res) => {
            if (res.status === httpStatus.OK) {
                console.log("200 received");
                console.log(res.body);
                this.setState({
                  addressSuggestions: res.body.completions
                })
            }
  
        })
        .catch((err) => {
          console.error(err);
        });
  
  }

  state = {
    addressSuggestions: []
  };

  componentDidMount() {
    this.props.stateReset();
  }

  render() {
    const { classes, changeHandler, businessNumberSearchHandler, state, handleStateChange, dateHandler } = this.props;

    let country = COUNTRIES.find((item) => {
      return item.country === "AU";
    });

    return (
        <React.Fragment>
          
          <Grid container spacing={4}>
            
    
            <AgreementDate 
                changeHandler={changeHandler}
                state={state}
                dateHandler={dateHandler}
            />

            
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="nzbn"
                name="nzbn"
                label="Search"
                fullWidth
                onChange={changeHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={businessNumberSearchHandler}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                helperText="Search customer details using ABN, Company Name or Company Number"
                type="search"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    businessNumberSearchHandler()
                  }
                }}
                
              />
            </Grid>
              
    
            <Grid item xs={12}>
              <TextField
                required
                id="confidantName"
                name="name"
                label="Name of Other party we will share information with"
                fullWidth
                onChange={changeHandler}
                value={state.name}
                error={state.formErrors.includes("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="companyNumber"
                name="companyNumber"
                label="Business Number (if applicable)"
                fullWidth
                onChange={changeHandler}
                value={state.companyNumber}
              />
            </Grid>
            
            <Downshift id="downshift-simple"
            onStateChange={handleStateChange}
            selectedItem={state.registeredAddress}>
            {({
              getRootProps,
              getInputProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }) => (
                <Grid item xs={12} {...getRootProps({}, {suppressRefError: true})}>
                  <TextField
                    error={state.formErrors.includes("registeredAddress")}
                    required
                    id="address"
                    name="registeredAddress"
                    label="Registered Address"
                    fullWidth
                    InputProps={{
                      ...getInputProps({
                        onChange: (event) => {
                          event.persist();
                          this.getSuggestions(event);

                        }
                      }),
                      classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      },
                    }}
                    
                  />

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {
                          this.state.addressSuggestions.map((suggestion, index) => (
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion.full_address }),
                              highlightedIndex,
                              selectedItem,
                            })
                          ))
                        }
                        
                      </Paper>
                    ) : null}
                  </div>

                </Grid>
                
              
            )}
          </Downshift> 
            
          <Grid item xs={12} sm={6}>
              <FormControl 
                required   
                fullWidth 
                error={state.formErrors.includes("state")}
                
              >
                <InputLabel htmlFor="state-required">State</InputLabel>
                <Select
                  value={state.state}
                  onChange={changeHandler}
                  name="state"
                  inputProps={{
                    id: 'state'
                  }}
                >
                  { country.states.map((item) => {
                      return <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                  })}
                  
                </Select>
              </FormControl>
            </Grid>  

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="country"
                name="country"
                label="Country"
                fullWidth
                defaultValue="Australia"
                onChange={changeHandler}
                disabled
              />
            </Grid>
    
            <Grid item xs={12}>
              <FormControl 
                required   
                fullWidth 
                error={state.formErrors.includes("purpose")}
                
              >
                <InputLabel htmlFor="purpose-required">Purpose</InputLabel>
                <Select
                  value={state.purpose}
                  onChange={changeHandler}
                  name="purpose"
                  inputProps={{
                    id: 'purpose'
                  }}
                >
                  
                  <MenuItem value={"Explore the possibility of mutual business opportunities for the parties."}>Explore the possibility of mutual business opportunities for the parties.</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
                <FormHelperText>Why are you putting the Agreement in place</FormHelperText>
              </FormControl>
            </Grid>
            
            {state.purpose === "Other" && (
              <Grid item xs={12}>
                <TextField
                  id="otherPurpose"
                  name="otherPurpose"
                  label="Enter other purpose"
                  fullWidth
                  onChange={changeHandler}
                  required
                  error={state.formErrors.includes("otherPurpose")}
                  
                />
              </Grid>
            )}
    
            <Grid item xs={12} sm={6}>
              <FormControl 
                required   
                fullWidth 
                error={state.formErrors.includes("terms")}
                
              >
                <InputLabel htmlFor="term-required">Term</InputLabel>
                <Select
                  value={state.terms}
                  onChange={changeHandler}
                  name="terms"
                  inputProps={{
                    id: 'terms'
                  }}
                >
                  
                  <MenuItem value={"1 Month"}>1 Month</MenuItem>
                  <MenuItem value={"2 Months"}>2 Months</MenuItem>
                  <MenuItem value={"3 Months"}>3 Months</MenuItem>
                  <MenuItem value={"4 Months"}>4 Months</MenuItem>
                  <MenuItem value={"5 Months"}>5 Months</MenuItem>
                  <MenuItem value={"6 Months"}>6 Months</MenuItem>
                  <MenuItem value={"7 Months"}>7 Months</MenuItem>
                  <MenuItem value={"8 Months"}>8 Months</MenuItem>
                  <MenuItem value={"9 Months"}>9 Months</MenuItem>
                  <MenuItem value={"10 Months"}>10 Months</MenuItem>
                  <MenuItem value={"11 Months"}>11 Months</MenuItem>
                  <MenuItem value={"12 Months"}>12 Months</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            {state.terms === "Other" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  id="other"
                  name="other"
                  label="Enter other term"
                  fullWidth
                  onChange={changeHandler}
                  required
                  error={state.formErrors.includes("other")}
                  
                />
              </Grid>
            )}
                 

            
            
          </Grid>
        </React.Fragment>
      );
  }  
}

export default withStyles(styles)(CAFormAU);
