import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import superagent from 'superagent';
import httpStatus from 'http-status';
import debounce from 'lodash/debounce';
import Divider from '@material-ui/core/Divider';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import config from './Config';

import styles from './MSAFormStyles';
import { COUNTRIES } from "./config/countries";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AgreementDate from './AgreementDate';

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {

  return (
    <React.Fragment key={suggestion.id}>
      <MenuItem
        {...itemProps}
        component="div"
      >
        {suggestion.full_address}
      </MenuItem>
      <Divider />
    </React.Fragment>  
  );
}



class MSAFormAU extends Component {

  constructor(props) {
    super(props);

    // delays the call to getSuggestions by 300ms
    this.getSuggestions = debounce(this.getSuggestions, 300);

  }
  

  async getSuggestions(event) {

    if (!event.target.value) {
      this.setState({
        addressSuggestions: []
      })
      return;
    }
  
    const inputValue = event.target.value.trim().toLowerCase();

    let {auAddressFinderAPI, auAddressFinderAPIKey} = config;

    superagent
        .get(auAddressFinderAPI)
        .query({
          key: auAddressFinderAPIKey,
          q: inputValue,
          format: 'json',
          gnaf: 1
        })
        
        .accept('application/json')
        .then((res) => {
            if (res.status === httpStatus.OK) {
                console.log("200 received");
                console.log(res.body);
                this.setState({
                  addressSuggestions: res.body.completions
                })
            }
  
        })
        .catch((err) => {
          console.error(err);
        });
        

  
  }

  state = {
    addressSuggestions: []
  };

  componentDidMount() {
    this.props.stateReset();

  }


  render() {

    const { classes, changeHandler, businessNumberSearchHandler, state, handleStateChange, dateHandler } = this.props;
    let country = COUNTRIES.find((item) => {
      return item.country === "AU";
    });

    return (
      <React.Fragment>
        
        <Grid container spacing={4}>
          
  
          <AgreementDate 
              changeHandler={changeHandler}
              state={state}
              dateHandler={dateHandler}
          />

           
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="nzbn"
              name="nzbn"
              label="Search"
              fullWidth
              onChange={changeHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={businessNumberSearchHandler}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText="Search customer details using ABN, Company Name or Company Number"
              type="search"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  businessNumberSearchHandler()
                }
              }}
              
            />
          </Grid>
          

          <Grid item xs={12}>
            <TextField
              required
              id="customerName"
              name="name"
              label="Customer Name"
              fullWidth
              onChange={changeHandler}
              value={state.name}
              error={state.formErrors.includes("name")}
              helperText="Legal name as on Companies Office Register"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="companyNumber"
              name="companyNumber"
              label="Business Number"
              fullWidth
              onChange={changeHandler}
              value={state.companyNumber}
              error={state.formErrors.includes("companyNumber")}
            />
          </Grid>
          
          
          <Downshift id="downshift-simple"
            onStateChange={handleStateChange}
            selectedItem={state.registeredAddress}
          >

            {({
              getRootProps,
              getInputProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem
              
            }) => (
                <Grid item xs={12} {...getRootProps({}, {suppressRefError: true})}>
                  <TextField
                    ref={this.address_line_1}
                    error={state.formErrors.includes("registeredAddress")}
                    required
                    id="address"
                    name="registeredAddress"
                    label="Registered Address"
                    fullWidth
                    InputProps={{
                      ...getInputProps({
                        
                        onChange: (event) => {
                          event.persist();
                          this.getSuggestions(event);
                          
                        }
                        
                      }),
                      classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      },
                    }}
                    
                    
                  />

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {
                          
                          this.state.addressSuggestions.map((suggestion, index) => (
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion.full_address }),
                              highlightedIndex,
                              selectedItem,
                            })
                          ))
                        }
                        
                      </Paper>
                    ) : null}
                  </div>

                </Grid>
                
              
            )}
          </Downshift> 

          <Grid item xs={12} sm={6}>
              <FormControl 
                required   
                fullWidth 
                error={state.formErrors.includes("state")}
                
              >
                <InputLabel htmlFor="state-required">State</InputLabel>
                <Select
                  value={state.state}
                  onChange={changeHandler}
                  name="state"
                  inputProps={{
                    id: 'state'
                  }}
                >
                  { country.states.map((item) => {
                      return <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                  })}
                  
                </Select>
              </FormControl>
            </Grid>      
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              defaultValue="Australia"
              onChange={changeHandler}
              disabled
            />
          </Grid>

          
                
          { /* 
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  name="includePartnering"
                  color="primary"
                  onChange={changeHandler}
                />
              }
              label="Include Partnering"
            />
          </Grid>
            */}      
          
          
        </Grid>
      </React.Fragment>
    );

  }

  
}

export default withStyles(styles)(MSAFormAU);
