import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import config from './Config';

import styles from './SignInStyles';


class SignIn extends Component {

  login = () => {
    this.props.auth.login();
  }

  

  render() {

    const { classes } = this.props;

    return (
        <div className={classes.root}>
          <div className={classes.layout}>
            <Paper className={classes.paper} elevation={10}>
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <Typography component="h1" variant="h4">
                {config.appName}
              </Typography>


              <Typography component="p" variant="body1" align="left" className={classes.intro}>
                {config.appName} is a tool to guide users in the automatic generation of commercial agreements from templates.
              </Typography>
              
              <Button
                  
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={this.login}
                >
                  Get Started
                  <ArrowRightIcon className={classes.rightIcon}/>
                </Button>
    
              
            </Paper>
          </div>
        </div>  
      );
  }

  
}

export default withStyles(styles)(SignIn);
