import history from '../history';
import * as Msal from 'msal';
import auth0 from 'auth0-js';
import { AZURE_AUTH_CONFIG } from './azure-config';
import { AUTH0_AUTH_CONFIG } from './auth0-config';

export default class Auth {

  idTokenPayload = {};

  constructor() {

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.getReferrer = this.getReferrer.bind(this);
    this.getAuthProvider = this.getAuthProvider.bind(this);

    if (process.env.REACT_APP_authenticator === 'AUTH0') {

      this.auth = new auth0.WebAuth({
        domain: AUTH0_AUTH_CONFIG.domain,
        clientID: AUTH0_AUTH_CONFIG.clientId,
        redirectUri: AUTH0_AUTH_CONFIG.callbackUrl,
        responseType: 'token id_token',
        scope: 'openid email',
        auto_login: false
        
      });

      this.useAzure = false;

    } else {

      this.auth = new Msal.UserAgentApplication(
        AZURE_AUTH_CONFIG.clientId, 
        AZURE_AUTH_CONFIG.authority, // authority
        this.tokenReceivedCallback,
        {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
          redirectUri: AZURE_AUTH_CONFIG.redirectUri
        }
      );

      this.useAzure = true;

    }

  }

  tokenReceivedCallback(errorDesc, token, error, tokenType) {
    if (token) {
      
      console.log('tokenReceivedCallback',token);
      const user = this.getUser();
      console.log('user', user);
      if (user) {
        localStorage.setItem("email", user.displayableId);
        localStorage.setItem("name", user.name);
        
        if (this.getReferrer()) {
          history.replace(this.getReferrer());
        } else {
          history.replace('/home/nz/msa');
        }
      }
      
    } else {
        console.log(error + ":" + errorDesc);
        history.replace('/error');
    }
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('email', authResult.idTokenPayload.email);
    //history.replace('/');
  }

  setReferrer(referrer) {
    localStorage.setItem('gaido-redirect', referrer);
  }

  getReferrer() {
    return localStorage.getItem('gaido-redirect');
  }

  clearReferrer() {
    localStorage.removeItem('gaido-redirect');
  }

  handleAuthentication() {

    console.log('handleAuthentication');

    return new Promise((resolve, reject) => {
      this.auth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          
          this.setSession(authResult);
          this.idTokenPayload = authResult.idTokenPayload;

          resolve(authResult);
          
        } else if (err) {
          
          reject(err);
          
        }
      });
    });
  }  

  login() {
    if (this.useAzure === true) {
      this.auth.loginRedirect(); 
    } else {
      this.auth.authorize();
    }  
  }

  
  logout() {

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('email');
    localStorage.removeItem('gaido-redirect');
    localStorage.removeItem('name');
    
    this.auth.logout();

    
  }

  isAuthenticated() {

    if (this.useAzure === true) {

      const idToken = this.getIdToken();
      const user = this.auth.getUser();
      let expiresAt = null;
      //
      if (user) {
        expiresAt = JSON.parse(user.idToken.exp);
      }

      const loggedIn = idToken !== null && user != null && new Date().getTime() / 1000 <= expiresAt;

      if (!loggedIn) {
          console.error('id token has expired, redirecting to login...');
          return false;
      }
      
      return loggedIn;

    } else {

      let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      return new Date().getTime() < expiresAt;

    }
    
    

    
    
  }

  getIdToken() {
    const idToken = localStorage.getItem('msal.idtoken');
    
    return idToken;
  }

  getEmail() {
    return localStorage.getItem('email');
  }

  getName() {
    return localStorage.getItem('name');
  }

  getAuthProvider() {
    return this.auth;
  }


}
