const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100vh'
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        //marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      },
    layout: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        position: 'relative',
        top: '30%',
        transform: 'translateY(-50%)'
      },
      title: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(4),
          textAlign: 'center',
      },
      button: {
        marginTop: theme.spacing(3),
        //alignItems: 'center',
      },
      paragraph: {
          marginBottom: theme.spacing(2)
      }

      
    
  });

  export default styles;
