import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Acknowledgement from './Acknowledgement';
import SearchDialog from './SearchDialog';
import Auth from './Auth/Auth';

import superagent from 'superagent';
import httpStatus from 'http-status';

import styles from './AgreementFormContainerStyles';
//import Promise from 'promise-polyfill';
//import isNil from 'lodash/isNil';

import preparePayload from './Util/AgreementPayloadUtil';
//import history from './history';
import config from './Config';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from './Util/AgreementFormValidator';

//import AgreementFormFactory from './Util/AgreementFormFactory';
import MessageSnackBar from './MessageSnackBar';
import Snackbar from '@material-ui/core/Snackbar';

import NZBusinessNumberSearch  from './NZBusinessNumberSearchUtil';
import AUBusinessNumberSearch  from './AUBusinessNumberSearchUtil';
import { COUNTRIES } from "./config/countries";

import { Switch, Redirect } from 'react-router-dom';
import SecuredRoute from './SecuredRoute';

import MSAForm from './MSAForm';
import CAForm from './ConfidentialityAgreementForm';

import MSAFormAU from './MSAForm-AU';
import CAFormAU from './ConfidentialityAgreementForm-AU';


const auth = new Auth();

const initialState = {
  "userName": "",
    "email": "",
    "effectiveDate": moment().format('YYYY-MM-DD'),
    "name": "",
    "companyNumber": "",
    "registeredAddress": "",
    "purpose": "",
    "otherPurpose": null,
    "terms": "",
    "other": null,
    "city": "",
    "zip": "",
    "country": "New Zealand",
    "nzbn": "",
    "includePartnering": false,
    "dialogOpen": false,
    "nzbnSearchResult": [],
    "isFetchingBusinessNumber": false,
    "errors": false,
    "formErrors": [],
    "isProcessing": false,
    "state": "",
    "snackBarOpen": false,
    "snackBarVariant": 'error',
    "snackBarMessage": '',
    "onExecution": false
};




class AgreementFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleBNSearch = this.handleBNSearch.bind(this);
    this.handleBNSearchDialogClose = this.handleBNSearchDialogClose.bind(this);
    this.handleBNSearchOk = this.handleBNSearchOk.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.validate = this.validate.bind(this);
    this.initialiseState = this.initialiseState.bind(this);
    this.autoSelectJurisdiction = this.autoSelectJurisdiction.bind(this);
    
  }

  state = {
    ...initialState
  }

  determineAgreementTitle() {

    let title;
    let { pathname } = this.props.location;

    if (pathname === '/home/nz/msa') {
      title = 'Master Agreement (NZ)';
    } else if (pathname === '/home/nz/conf') {
      title = 'Confidentiality Agreement (NZ)';
    } else if (pathname === '/home/au/msa') {
      title = 'Master Agreement (AU)';
    } else if (pathname === '/home/au/conf') {
      title = 'Confidentiality Agreement (AU)';
    } 
  
    return title;
  }

  initialiseState() {
    this.setState({
      ...initialState
    })
    
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  }

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({registeredAddress: changes.selectedItem})
      this.autoSelectJurisdiction(changes.selectedItem);
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({registeredAddress: changes.inputValue})
      this.autoSelectJurisdiction(changes.inputValue);
    }
  }
  

  handleBNSearch(e) {

    this.setState({
      dialogOpen: true,
      isFetchingBusinessNumber: true
    });

    try {

      let agreementType = this.props.state.activeForm;

      if (agreementType === 'msa' || agreementType === 'conf' ) {
        
        let searchUtil = new NZBusinessNumberSearch();
        
        searchUtil.searchCompanyDetails(this.state.nzbn)
            .then((resultWithAddresses) => {
                this.setState({
                  isFetchingBusinessNumber: false,
                  bnResult: resultWithAddresses
                });
            });
          ;

      }

      if (agreementType === 'msa-au' || agreementType === 'conf-au' ) {
        
        let searchUtil = new AUBusinessNumberSearch();
        
        searchUtil.searchCompanyDetails(this.state.nzbn)
            .then((resultWithAddresses) => {
                this.setState({
                  isFetchingBusinessNumber: false,
                  bnResult: resultWithAddresses
                });
            })
            .catch((err) => {
              console.error("Business Number fetch error: ", err);
              this.setState({
                isFetchingBusinessNumber: false,
                bnResult: []
              });
            });
          ;

      }

    } catch (err) {
      console.error("Business Number fetch error: ", err);
      this.setState({
        isFetchingBusinessNumber: false,
        bnResult: []
      });
    }

    
  }

  searchCallback(results) {
    console.log("searchcallback called");
  }

  autoSelectJurisdiction(address) {
    if (this.props.state.activeForm.includes("-au")) {
      // check the address for the state value
      let country = COUNTRIES.find((item) => {
        return item.country === "AU";
      });

      let state = country.states.find((item) => {
        return (address.includes(` ${item.code} `) || address.includes(`${item.code} `));
      });

      if (state) {
        this.setState({
          state: state.code
        });
      }
      

    }
  }

  handleBNSearchOk(entityRecord) {

    const {entityName, sourceRegisterUniqueId, address} = entityRecord;

    // if for AU, send back the Business Number to the form
    // if for NZ, send back the Company Number
    let companyNumber = this.props.state.activeForm.includes("-au") ? entityRecord.nzbn : sourceRegisterUniqueId;

    this.setState({
      dialogOpen: false,
      isFetchingBusinessNumber: false,
      name: entityName,
      companyNumber: companyNumber,
      registeredAddress: address
    });

    this.autoSelectJurisdiction(address);

  }

  handleBNSearchDialogClose(e) {
    this.setState({
      dialogOpen: false,
      isFetchingBusinessNumber: false
    });
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }

    let change = {};

    change[e.target.name] = (e.target.name !== 'includePartnering' && e.target.name !== 'onExecution') ? e.target.value : e.target.checked;

    this.setState(change)
  }

  handleDateChange(date) {
    console.log('date changed', date);
    this.setState({
      effectiveDate: moment(date).format('YYYY-MM-DD')
    });

  }

  handleAddressChange(address) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }

    this.setState({
      registeredAddress: address
    })

  }

  getEndpoint = () => {
    let endpoint = '';

      if (this.props.state.activeForm === 'msa' || this.props.state.activeForm === 'msa-au')  {
        endpoint= 'master-agreement';
      }

      if (this.props.state.activeForm === 'conf' || this.props.state.activeForm === 'conf-au')  {
        endpoint= 'confidentiality-agreement';
      }

      return endpoint;

  }

  validate(activeForm) {

    let required = validator.validateCommonFields(this.state);

    if (activeForm === 'msa') {
      required = required.concat(validator.validateMsa(this.state));
    }

    if (activeForm === 'msa-au') {
      required = required.concat(validator.validateMsaAU(this.state));
    }

    if (activeForm === 'conf') {
      required = required.concat(validator.validateConf(this.state));
    }

    if (activeForm === 'conf-au') {
      required = required.concat(validator.validateConfAU(this.state));
    }

    return required;

  }

  handleSubmit(e) {

      e.preventDefault();

      if (!auth.isAuthenticated()) {
        auth.logout();
        return;
        //history.replace("/");
      }

      let formErrors = this.validate(this.props.state.activeForm);

      if (formErrors.length > 0) {
        console.log("validation errors found, exiting handleSubmit");

        this.setState({
          errors: true,
          formErrors: formErrors
        });

        return;

      } else {
        this.setState({
          errors: false,
          formErrors: []
        })
      }

      const payload = preparePayload({
        activeForm: this.props.state.activeForm,
        email: auth.getEmail(),
        state: this.state
      });
      
      console.log(JSON.stringify(payload, null, 2));

      const { docAssistMuleApi } = config;

      this.setState({
        isProcessing: true
      });

      superagent
            .post(`${docAssistMuleApi}/${this.getEndpoint()}`)
            .set('Content-Type', 'application/json')
            .send(payload)
            .accept('application/json')
            .then((res) => {
                if (res.status === httpStatus.OK || res.status === httpStatus.ACCEPTED) {
                    //console.log("200 received");
                    this.setState({
                      isProcessing: false
                    });
                    this.props.submitHandler(res.body.id);
                }

            })
            .catch((err) => {
              /** TODO - add error state which will trigger a small notification that an error occurred */
              this.setState({
                isProcessing: false,
                snackBarOpen: true,
                snackBarMessage: 'Something went wrong when attempting to generate the document.  Please try again later or contact support if the problem persists.',
                snackBarVariant: 'error'
              });
              console.error(err);
            });
            
            

  }

  render() {

    const { classes } = this.props;
    const { showAcknowledgement, requestId, activeForm } = this.props.state;

    let showCompanyNumber = true;

    if (activeForm && activeForm.includes("-au")) {
      showCompanyNumber = false;
    }

   let formHandlers = {
      changeHandler: this.handleChange,
      addressChangeHandler: this.handleAddressChange,
      businessNumberSearchHandler: this.handleBNSearch,
      state: this.state,
      handleStateChange: this.handleStateChange,
      dateHandler: this.handleDateChange,
      stateReset: this.initialiseState
   };
   

    let title = this.determineAgreementTitle();

    if (this.props.location.pathname === "/") {
      return <Redirect to="/home/nz/msa" />
    }


    return (
      
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center" className={classes.title}>
              {title}
            </Typography>
            
            {showAcknowledgement === true ? (
              <Acknowledgement requestId={requestId}/>
            ) : (
              <React.Fragment>
                <form>

                  <Switch>
                    
                    <SecuredRoute 
                      exact
                      path="/home/nz/msa"
                      component={MSAForm}
                      auth={auth}
                      handlers={formHandlers}
                    />

                    <SecuredRoute 
                        path="/home/nz/conf"
                        component={CAForm}
                        auth={auth}
                        handlers={formHandlers}
                    />

                    <SecuredRoute 
                        path="/home/au/msa"
                        component={MSAFormAU}
                        auth={auth}
                        handlers={formHandlers}
                    />

                    <SecuredRoute 
                        path="/home/au/conf"
                        component={CAFormAU}
                        auth={auth}
                        handlers={formHandlers}
                    />


                  </Switch>  

                  <div className={classes.buttons}>
                      {this.state.isProcessing === true ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled
                            className={classes.button}
                        >
                          <CircularProgress />
                        </Button> ) : (
                          <Button
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              onClick={this.handleSubmit}
                          >
                              Generate
                          </Button>
                        )
                      }
                      
                      
                  </div>
                </form>
                
              </React.Fragment>
            )}

            <SearchDialog 
              dialogOpen={this.state.dialogOpen}
              handleClose={this.handleBNSearchDialogClose}
              input={this.state.nzbn}
              bnResult={this.state.bnResult}
              isFetchingBusinessNumber={this.state.isFetchingBusinessNumber}
              handleOk={this.handleBNSearchOk}
              showCompanyNumber={showCompanyNumber}
            />

          </Paper>

          <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
              open={this.state.snackBarOpen}
              autoHideDuration={10000}
              onClose={this.handleSnackBarClose}
              >
              
              <MessageSnackBar
                  onClose={this.handleSnackBarClose}
                  variant={this.state.snackBarVariant}
                  message={this.state.snackBarMessage}
              />
          </Snackbar>

        </div>
      
    );
  }
}



export default withStyles(styles)(AgreementFormContainer);
