const drawerWidth = 360;

const styles = theme => ({
    
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      height: '100vh',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appName: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3)
    }
    
  });

  export default styles;
