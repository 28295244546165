const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    background: 'radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)',
    opacity: 0.9
  },
    layout: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      
      background: 'radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)'
    },
    paper: {
      //marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    
    submit: {
      marginTop: theme.spacing(5),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    intro: {
      marginTop: theme.spacing(3),
      width: '100%'
    }
  });

  export default styles;
