import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
});


class Callback extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography>Loading...</Typography>
        <CircularProgress size={80}/>
      </div>
    );
  }
}

export default withStyles(styles)(Callback);
