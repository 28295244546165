import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MenuDrawer from './MenuDrawer';
import AgreementFormContainer from './AgreementFormContainer';
import styles from './MainStyles';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HistoryContainer from './HistoryContainer';
import Analytics from './Analytics';

import withWidth from '@material-ui/core/withWidth';



class Main extends Component { 

    state = {
        open: (this.props.width !== 'sm' && this.props.width !== 'xs'),
        anchor: 'left',
        activeForm: 'msa',
        showAcknowledgement: false,
        requestId: null,
        path: '/home/nz/msa'
    };

    logout = () => {
        this.props.auth.logout();
    }
    
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };
    
    handleDrawerClose = () => {
        this.setState({ open: false });
    }; 

    handleMenuItemClick = (item) => {

        if (!this.props.auth.isAuthenticated()) {
            this.props.auth.logout();
            return;
        }

        this.setState({
            activeForm: item,
            showAcknowledgement: false,
            open: item === 'history' ? false : true
        });
        
    }

    handleAgreementFormSubmission = (id) => {
        this.setState({
            showAcknowledgement: true,
            requestId: id
        });
    }

    getActiveForm(pathname) {
        if (pathname === '/home/nz/msa') {
            return 'msa';
          } else if (pathname === '/home/nz/conf') {
            return 'conf';
          } else if (pathname === '/home/au/msa') {
            return 'msa-au';
          } else if (pathname === '/home/au/conf') {
            return 'conf-au';
          } else if (pathname === '/home/history') {
            return 'history';
          } else if (pathname === '/home/analytics') {
            return 'analytics';
          }  
    }

    handleResize = e => {
        
        this.setState(prevState => {

            return {
                open: prevState.open && (this.props.width !== 'sm' && this.props.width !== 'xs')
            }
            
        });
      };

    componentDidMount() {

        window.addEventListener("resize", this.handleResize);

        let activeForm = 'msa';
        let { pathname } =  this.props.location;

        activeForm = this.getActiveForm(pathname);

        if (pathname === '/') {
            pathname = '/home/nz/msa';
            activeForm = 'msa';
        }


        this.setState({
            activeForm,
            path: pathname,
            open: activeForm === 'history' ? false : true
        });

        this.unlisten = this.props.history.listen((location, action) => {
            console.log("route changed", location.pathname);

            let activeForm = this.getActiveForm(location.pathname);

            this.setState({
                showAcknowledgement: false,
                requestId: null,
                path: location.pathname,
                activeForm: activeForm,
                open: activeForm === 'history' ? false : true
            });
        });

    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener("resize", this.handleResize);
    }

    render () {

        const { classes, theme } = this.props;
        const { anchor, open } = this.state;

        let component = <AgreementFormContainer state={this.state} submitHandler={this.handleAgreementFormSubmission} location={ this.props.location}/>;

        if (this.state.activeForm === 'history') {
            component = <HistoryContainer user={this.props.auth.getEmail()}/>
        } else if (this.state.activeForm === 'analytics') {
            component = <Analytics />
        }

        return (
            
                <div className={classes.appFrame}>
                    <AppBar
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: open,
                            [classes[`appBarShift-${anchor}`]]: open,
                        })}
                    >
                        <Toolbar disableGutters={!open}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <AccountCircle/>
                            
                            
                            <Typography variant="body1" color="inherit" className={classes.grow}>
                                {this.props.auth.getName() || this.props.auth.getEmail()}
                            </Typography>

                            <Button color="inherit" onClick={this.logout} className={classNames(!open && classes.buttonPadding)}>LogOut</Button>
                            
                        </Toolbar>
                    </AppBar>


                            <MenuDrawer 
                                state={this.state} 
                                handleDrawerClose={this.handleDrawerClose} 
                                handleDrawerOpen={this.handleDrawerOpen}
                                itemClickHandler={this.handleMenuItemClick}
                                theme={theme}
                                email={this.props.auth.getEmail()}
                            />
                    

                    <div
                        className={classNames(classes.content, classes[`content-${anchor}`], {
                        [classes.contentShift]: open,
                        [classes[`contentShift-${anchor}`]]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        {component}
                    </div>    

                </div>
            
        );
    }
};

export default withWidth()(withStyles(styles, { withTheme: true })(Main));
