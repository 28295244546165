import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const SecuredRoute = ({component: Component, auth, handlers}) => {

    return (
        <Route render={(props) => {
            //console.log('secured route props', props);
            return auth.isAuthenticated() === true
                  ? <Component {...props}  auth={auth} {...handlers}/>
                  : <Redirect to='/' /> 
            }} 
        />
    )        
};

export default SecuredRoute;
