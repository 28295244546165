const styles = theme => ({
    dateField: {
      marginRight: theme.spacing(1),
      width: 200,
    },
    paper: {
      zIndex: 100,
      marginTop: theme.spacing(1),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    button: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });

export default styles;
