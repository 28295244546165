import config from './Config';
import superagent from 'superagent';
import httpStatus from 'http-status';
import Promise from 'promise-polyfill';
import isNil from 'lodash/isNil';

class NZBusinessNumberSearch {

  fetchEntities(nzbn) {

    const { nzbnApi, nzbnApiToken } = config;
  
    return superagent
        .get(nzbnApi)
        .query({
          "search-term": nzbn
        })
        .set({
          'Authorization': `Bearer ${nzbnApiToken}`
        })
  }
  
  fetchAddress(nzbn) {
  
    const { nzbnApi, nzbnApiToken } = config;
  
    return superagent
        .get(`${nzbnApi}/${nzbn}`)
        .set({
          'Authorization': `Bearer ${nzbnApiToken}`
        })
  }
  
  prepareEntitySearchResult(res) {
  
    return res.body.items.map( async (item) => {
      const entity = await this.fetchAddress(item.nzbn);
  
      //console.log(entity);
  
      if (entity.status === httpStatus.OK) {
        
        if (entity.body && entity.body.registeredAddress) {
  
          let address = '';
  
          if (entity.body.registeredAddress.length > 0) {
            const {address1, address2, address3} = entity.body.registeredAddress[0];
  
            address = address1;
            if (!isNil(address2)) {
              address = `${address}, ${address2}`;
            }
  
            if (!isNil(address3)) {
              address = `${address}, ${address3}`;
            }
          }
          
          return {
            entityName: item.entityName,
            sourceRegisterUniqueId: item.sourceRegisterUniqueId,
            nzbn: item.nzbn,
            address: address
          }
        }
      }  
  
    });
  }

  async searchCompanyDetails (nzbn) {

    const res = await this.fetchEntities(nzbn);
  
    if (res.status === httpStatus.OK) {
  
        const resultWithAddresses = await Promise.all(this.prepareEntitySearchResult(res));
  
        return resultWithAddresses;
  
    } else {
        return [];
    }
  
  }


}



export default NZBusinessNumberSearch;




