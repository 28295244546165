import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import superagent from 'superagent';
import httpStatus from 'http-status';
import config from './Config';
import Typography from '@material-ui/core/Typography';

import MessageSnackBar from './MessageSnackBar';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        height: '100vh',
        overflow: 'auto',
      },
      progressLabel: {
          marginBottom: theme.spacing(2)
      }
});    


class HistoryContainer extends Component {

    state = {
        history: [],
        isFetching: true,
        snackBarOpen: false,
        snackBarVariant: 'error',
        snackBarMessage: ''
    }

    handleSnackBarClose = () => {
        this.setState({ snackBarOpen: false });
    }

    componentDidMount() {
        console.log('fetching history...');

        superagent
            .get(`${config.docAssistAWSDocumentAPI}?user=${this.props.user}`)
            .set({
                'x-api-key': `${config.docAssistAWSDocumentAPIKey}`
            })
            .then((res) => {
                if (res.status === httpStatus.OK) {
                    this.setState({
                        history: res.body.data,
                        isFetching: false
                    });
                    
                }

            })
            .catch((err) => {
                this.setState({
                    isFetching: false,
                    snackBarOpen: true,
                    snackBarMessage: 'There was an error fetching your request history',
                    snackBarVariant: 'error'
                });
                
                console.error(err);
            });
    }

    render() {

        const {classes} = this.props;

        return (
            <div className={classes.content}>

                {this.state.isFetching === true ? (
                    <Typography variant="h6" className={classes.progressLabel}>Fetching last 5 Agreement requests...</Typography>
                ) : (
                    <Typography variant="h6" className={classes.progressLabel}>Last 5 Agreement requests</Typography>
                )}
                
                <div className={classes.tableContainer}>
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Request ID</TableCell>
                                    <TableCell>Document ID</TableCell>
                                    <TableCell>Date Requested</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Agreement Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.history.map(row => {
                                return (
                                    <TableRow 
                                        key={row.requestId}
                                    >
                                        <TableCell>
                                            {row.requestId}
                                        </TableCell>
                                        <TableCell>
                                            {row.documentId}
                                        </TableCell>
                                        <TableCell>{row.requestDate}</TableCell>
                                        <TableCell>{row.status === 'Agreement successfully generated and sent.' ? 'OK' : row.status}</TableCell>
                                        <TableCell >{row.agreementType === 'MSA' ? 'Master Agreement' : 'Confidentiality Agreement'}</TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackBarOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackBarClose}
                    >
                    
                    <MessageSnackBar
                        onClose={this.handleSnackBarClose}
                        variant={this.state.snackBarVariant}
                        message={this.state.snackBarMessage}
                    />
                </Snackbar>

            </div>
        );
    }
}

export default withStyles(styles)(HistoryContainer);
