

/* See the .env* files for the environment variable values */
const config = {
    // nzbn search properties
    nzbnApiToken: process.env.REACT_APP_nzbnApiToken,
    nzbnApi: process.env.REACT_APP_nzbnApi,
    // mule api called to generate the documents
    docAssistMuleApi: process.env.REACT_APP_docAssistMuleApi,
    // nz post api properties
    nzPostApi: process.env.REACT_APP_nzPostApi,
    nzPostClientId: process.env.REACT_APP_nzPostClientId,
    nzPostClientSecret: process.env.REACT_APP_nzPostClientSecret,
    // document request api properties
    docAssistAWSDocumentAPI: process.env.REACT_APP_docAssistAWSDocumentAPI, 
    docAssistAWSDocumentAPIKey: process.env.REACT_APP_docAssistAWSDocumentAPIKey,
    // application properties
    appName: process.env.REACT_APP_appName,
    version: process.env.REACT_APP_version,
    // properties related to ABN search
    abnApiToken: process.env.REACT_APP_abnApiToken,
    auBusinessSearchAPI: process.env.REACT_APP_auBusinessSearchAPI, // mulesoft api
    // AU Address finder properties
    auAddressFinderAPI: process.env.REACT_APP_auAddressFinderAPI,
    auAddressFinderAPIKey: process.env.REACT_APP_auAddressFinderAPIKey
};

export default config;
