import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AgreementRequestStatusDialog from './AgreementRequestStatusDialog';
import superagent from 'superagent';
import httpStatus from 'http-status';
import config from './Config';

import MessageSnackBar from './MessageSnackBar';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
    reference: {
      marginTop: theme.spacing(3)
    },
    id: {
        color: theme.palette.primary.main,
        display: "inline",
        textTransform: 'none'
    }
    
  });

class Acknowledgement extends Component  {

    state = {
        dialogOpen: false,
        result: {},
        isFetching: false,
        snackBarOpen: false,
        snackBarVariant: 'error',
        snackBarMessage: ''
    };

    handleDialogClose = () => {
        this.setState({
          dialogOpen: false,
          isFetching: false
        });
    }

    handleSnackBarClose = () => {
        this.setState({ snackBarOpen: false });
    }

    handleDocumentIdClick = () => {
        this.setState({
            dialogOpen: true,
            isFetching: true
        });

         
        superagent
            .get(`${config.docAssistAWSDocumentAPI}/${this.props.requestId}`)
            .set({
                'x-api-key': `${config.docAssistAWSDocumentAPIKey}`
            })
            .then((res) => {
                if (res.status === httpStatus.OK) {
                    console.log(res.body);
                    this.setState({
                        result: res.body.data[0],
                        isFetching: false
                    });
                    
                }

            })
            .catch((err) => {
                this.setState({
                    dialogOpen: false,
                    isFetching: false,
                    snackBarOpen: true,
                    snackBarMessage: 'There was an error fetching details of your request',
                    snackBarVariant: 'error'
                });
                console.error(err);
            });
           

    }

    render() {

        const {classes, requestId} = this.props;

        return (
                
                <React.Fragment>
                    
                    <Typography variant="subtitle1">
                        Your request is currently being processed, please check your email shortly.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.reference}>
                        Your reference number is: <Button component="span" className={classes.id} onClick={this.handleDocumentIdClick}>{requestId}</Button>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.reference}>
                        Please use your reference number to check the status of your request if the generated agreement does not show up in your inbox.
                    </Typography>

                    <AgreementRequestStatusDialog 
                        dialogOpen={this.state.dialogOpen}
                        handleClose={this.handleDialogClose}
                        result={this.state.result}
                        isFetching={this.state.isFetching}
                    />

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.snackBarOpen}
                        autoHideDuration={3000}
                        onClose={this.handleSnackBarClose}
                        >
                        
                        <MessageSnackBar
                            onClose={this.handleSnackBarClose}
                            variant={this.state.snackBarVariant}
                            message={this.state.snackBarMessage}
                        />
                    </Snackbar>

                </React.Fragment>
        );
    }

    
};

export default withStyles(styles)(Acknowledgement);
