import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Typography from '@material-ui/core/Typography';

import MenuItems from './MenuItems';
import styles from './MenuDrawerStyles';
import config from './Config';

class MenuDrawer extends Component {

    constructor(props) {
        super(props);
        
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);

    }

    handleMenuItemClick = (agreementType) => {
        this.props.itemClickHandler(agreementType);
    }

    render() {

        const { classes, theme } = this.props;
        const { anchor, open, path } = this.props.state;

        return (
                <Drawer
                    variant="persistent"
                    anchor={anchor}
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    
                    <div className={classes.drawerHeader}>
                        <div className={classes.appName}>
                        <Typography variant="subtitle1" color="secondary" component="div" >
                            Fujitsu {config.appName} 
                        </Typography>
                        <Typography variant="body2" component="div">
                            v{config.version}
                        </Typography>
                        </div>
                        <IconButton onClick={this.props.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    
                    <MenuItems itemClickHandler={this.handleMenuItemClick} activeForm={this.props.state.activeForm} path={path}/>

                </Drawer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MenuDrawer);
