import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import config from './Config';
import styles from './ErrorPageStyles';


class ErrorPage extends Component {

    logout = () => {
        this.props.auth.logout();
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static" >
                    <Toolbar>
                        <Typography variant="h6" color="inherit">
                            {config.appName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.layout}>
                    <Paper className={classes.paper}>
                        
                        <Typography component="h1" variant="h4" className={classes.title} color="secondary">
                            Oops!
                        </Typography>

                        <Typography component="p" variant="body1" align="left" className={classes.paragraph}>
                            Something went wrong while logging you in.  You may not be authorised to access the application.
                        </Typography>

                        <Typography component="p" variant="body1" align="left">
                            Please try again or contact support if the issue is not resolved.
                        </Typography>
                        
                        <Button color="primary" onClick={this.logout} className={classes.button}>
                            Back to Login page 
                        </Button>

                    
                    </Paper>
                </div>
                
            </div>
        );
    }
}

export default withStyles(styles)(ErrorPage);
