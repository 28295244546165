import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import { MENU } from "./config/menu";

import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

const styles = theme => ({
    
    nested: {
      paddingLeft: theme.spacing(4),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    firstDivider: {
        marginBottom: theme.spacing(2)
    },
    highlight: {
      fontFamily: 'FujitsuSansMedium'
    }
    

  });

function MenuItemLink(props) {

    
    const { icon, primary, to, action, classes, path } = props;

    const renderLink = React.useMemo(
        () =>
          React.forwardRef((itemProps, ref) => (
            // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
            // See https://github.com/ReactTraining/react-router/issues/6056
            <RouterLink to={to} {...itemProps} innerRef={ref} />
          )),
        [to],
      );

      let isSelected = path === to;

      return (
        <li>
          <ListItem button component={renderLink} 
            className={clsx(classes.nested)} 
            onClick={(event) => { 
              props.itemClickHandler(action)  
            } }
            selected={isSelected}
            >

                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText classes={{
                  primary: isSelected && classes.highlight
                }}>{primary}</ListItemText>

          </ListItem>
        </li>
      );
}  

const MenuItems = (props) => {

    const {classes, activeForm, path} = props;

    return (
        <List component="div">
            <Divider className={classes.firstDivider}/>

            {
                MENU.menu.map((menuItem) => {

                    if (menuItem.display === false) {
                      return null;
                    }

                    return <List key={menuItem.header} component="div" disablePadding>
                                <ListItem >
                                    <ListItemText>
                                        <Typography variant="button" color="inherit">
                                            {menuItem.header}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>

                                { menuItem.items.map((item) => {
                                      return <MenuItemLink key={item.action} icon={item.icon} primary={item.text} to={item.path} 
                                          action={item.action} classes {...props} activeForm={activeForm} path={path}/>
                                    
                                })}

                                {menuItem.divideAfter && <Divider className={classes.divider}/>}

                            </List>
                })

            }

            
        </List>
    );
};

export default withStyles(styles)(MenuItems);
