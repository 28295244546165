const preparePayload = ({activeForm, email, state}) => {

    let payload = {};

    payload["requestBy"] = {
      userName: email,
      email: email
    }

    const {effectiveDate, name, companyNumber, registeredAddress, nzbn, country} = state;

    if (activeForm === 'msa' || activeForm === 'msa-au') {
      let msaAgreement = {
        effectiveDate: effectiveDate,
        customer: {
          name: name,
          companyNumber: companyNumber,
          nzbn: nzbn,
          registeredAddress: registeredAddress,
          jurisdiction: state.state,
          country: activeForm === 'msa-au' ? "Australia" : country
        },
        includePartnering: state.includePartnering,
        onExecution: state.onExecution
      }
      payload["msaAgreement"] = msaAgreement;
    }

    if (activeForm === 'conf' || activeForm === 'conf-au') {

      const {purpose, terms, other, otherPurpose} = state;

      let confAgreement = {
        effectiveDate: effectiveDate,
        confidant: {
          name: name,
          companyNumber: companyNumber,
          registeredAddress: registeredAddress,
          jurisdiction: state.state,
          country: activeForm === 'conf-au' ? "Australia" : country
        },
        purpose: purpose === "Other" ? otherPurpose : purpose,
        term: terms === "Other" ? other : terms,
        onExecution: state.onExecution
      }
      payload["confAgreement"] = confAgreement;
    }

    return payload;

}

export default preparePayload;
