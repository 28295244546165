import {History, BarChart} from '@material-ui/icons';
import { FileDocumentOutline } from 'mdi-material-ui';
import React from 'react';

export const MENU = {
    "menu": [
        {
            "header": "Agreement Templates",
            "items": []
        },
        {
            "header": "AU",
            "items": [
                {
                    "text": "Master Agreement",
                    "action": "msa-au",
                    "icon": <FileDocumentOutline />,
                    "path": "/home/au/msa"
                },
                {
                    "text": "Confidentiality Agreement",
                    "action": "conf-au",
                    "icon": <FileDocumentOutline />,
                    "path": "/home/au/conf"
                }
            ]
        },
        {
            "header": "NZ",
            "items": [
                {
                    "text": "Master Agreement",
                    "action": "msa",
                    "icon": <FileDocumentOutline />,
                    "path": "/home/nz/msa"
                },
                {
                    "text": "Confidentiality Agreement",
                    "action": "conf",
                    "icon": <FileDocumentOutline />,
                    "path": "/home/nz/conf"
                }
            ],
            "divideAfter": true
        },
        {
            "header": "Agreement Request History",
            "items": [
                {
                    "text": "View Request Status/History",
                    "action": "history",
                    "icon": <History />,
                    "path": "/home/history"
                }
                
            ],
            "divideAfter": true
        },
        {
            "header": "Analytics",
            "items": [
                {
                    "text": "Dashboard",
                    "action": "",
                    "icon": <BarChart />,
                    "path": "/home/analytics"
                }
                
            ],
            "display": false
        }
    ]
}