import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import styles from './SearchDialogStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class AgreementRequestStatusDialog extends Component {

    render() {

        const {dialogOpen, handleClose, result, isFetching, classes} = this.props;
        let docType;

        if (!isEmpty(result)) {
            if (result.agreementType === 'MSA') {
                docType = 'Master Agreement';
            }
    
            if (result.agreementType === 'CONF') {
                docType = 'Confidentiality Agreement';
            }
        }
        

        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    maxWidth={false}
                >
                    <DialogTitle id="form-dialog-title">Agreement Request Status</DialogTitle>
                    <DialogContent>
                        {isFetching ? (
                            <div className={classes.loading}>
                                <CircularProgress />
                            </div>
                            
                        ): (
                            <React.Fragment>
                                <DialogContentText style={{ marginBottom: '24px' }}>
                                    Here are the details of your requested Agreement:
                                </DialogContentText>
                                {!isEmpty(result) ? (
                                    
                                    <React.Fragment>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <Typography>Request ID</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography>{result.requestId}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <Typography>Status</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography color="primary">{result.status}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <Typography>Filename</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography>{result.documentId}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <Typography>Date requested</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography>{result.requestDate}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <Typography>Type</Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography>{docType}</Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </React.Fragment>
                                    
                                    

                                ): <Typography>Your Document is still being processed, check back in a while.</Typography>}
                                
                            </React.Fragment>
                            
                            
                        )}
                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                        Close
                        </Button>
                    </DialogActions>  
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AgreementRequestStatusDialog);
