import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import styles from './SearchDialogStyles';
import SearchTable from './SearchTable';


class SearchDialog extends Component {

    state = {
        selected: {}
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
        this.handleRowDblClick = this.handleRowDblClick.bind(this);
    }

    handleAddressSelect(id) {

        let entityRecord = this.props.bnResult.find((item) => {
            let key = item.sourceRegisterUniqueId + item.nzbn + item.entityName
            return key === id;
        });

        this.setState({
            selected: entityRecord
        })


    }

    handleOk() {
        this.props.handleOk(this.state.selected);
    }

    handleRowDblClick(id) {
        this.handleOk();
    }

    render() {

        const {dialogOpen, handleClose, input, bnResult, isFetchingBusinessNumber, classes} = this.props;

        let resultText = `No results found for "${input}"`;

        if (!isEmpty(bnResult)) {

            resultText = `Here are the search results matching "${input}"`;
        }
        

        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    maxWidth={false}
                >
                    <DialogTitle id="form-dialog-title">Business Number, Company Name or Company Number Search</DialogTitle>
                    <DialogContent>
                        {isFetchingBusinessNumber ? (
                            <div className={classes.loading}>
                                <CircularProgress />
                            </div>
                            
                        ): (
                            <React.Fragment>
                                <DialogContentText >
                                    {resultText}
                                </DialogContentText>
                                {!isEmpty(bnResult) ? (
                                    <SearchTable 
                                        items={bnResult} 
                                        handleAddressSelect={this.handleAddressSelect}
                                        handleRowDblClick={this.handleRowDblClick}
                                        showCompanyNumber={this.props.showCompanyNumber}
                                    />
                                ): null}
                                
                            </React.Fragment>
                            
                            
                        )}
                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                        Close
                        </Button>
                        {!isEmpty(bnResult) ? ( 
                            <Button onClick={this.handleOk} color="primary" disabled={isFetchingBusinessNumber}>
                                Ok
                            </Button>) : null
                        }
                       
                    </DialogActions>  
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(SearchDialog);
